body {
  font-family: "Nunito";
  margin: 0px;
  padding: 0px;
  background-color: #FAFAFA;
  font-size: 20px;
  display: flex;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
}

figure {
  padding: 0px;
  margin: 0px;
}

a {
  text-decoration: none;
  color: inherit;
}

.TextLarge {
  font-size: 36px;
  font-weight: bold;
  color: black;
}

.TextMedium {
  font-size: 26px;
  font-weight: bold;
  color: black;
}

.TextSmall {
  font-size: 16px;
  font-family: "Lora";
  font-weight: 200;
  color: black;
  line-height: 1.7;
}

.Heading {
  font-size: 28px;
  font-weight: bold;
  color: black;
}

#App {
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  max-width: 1052px;
}

.LblLogo {
  width: 280px;
}

#NavContainer {
  display: flex;
  justify-content: center;
}

#Nav {
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0px;
  min-width: 1052px;
}

#NavLinks {
  display: flex;
  flex-direction: row;
  width: 400px;
  justify-content: space-between;
}

#NavLinks div:hover {
  cursor: pointer;
  color: #57B9BD
}

#RowMain {
  display: flex;
  flex-direction: row;
  margin-bottom:20px;
  justify-content: center;
}

#RowMainLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  object-fit: cover;
  padding: 100px;
  max-width: 650px;
  align-items: center;
  text-align: center;
}

#AppLogosLinks {
  display: flex;
  flex-direction: row;
  margin: 20px;
  width: 130px;
  justify-content: space-between;
}

.AppIcon {
  border-radius: 10px;
  width: 55px;
}

#RowMainRight {
  content: url(../images/Illustration.png);
  max-height: 430px;
  object-fit: cover;
  display: none;
}

.AppRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 30px 10px;
}

.Mockup {
  object-fit: contain;
  max-height: 600px;
  margin-top: -40px;
}

.AppLogo {
  margin: 10px 0 0px;
  width: 240px;
  margin-bottom: 18px;
}

.AppInfo div {
  margin-bottom: 10px;
}

.BrLogo {
  width: 180px;
}

.AppInfo {
  display: flex;
  flex-direction: column;
  max-width: 380px;
}

.SocialLinks {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.SocialLinks img {
  max-height: 45px;
  margin-right: 8px;
}

#RowValues {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0px 40px 0px;
}

.Values {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 35px;
  grid-column-gap: 35px;
}

.ValueInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.ValueInfo .TextMedium {
  text-align: center;
}

#RowAbout {
  display: none;
  flex-direction: column;
  align-items: center;
  margin: 50px 0px 20px 0px;
}

#AboutUsColumns {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.AboutColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AboutImage {
  width: 260px;
  height: 260px;
  border-radius: 130px;
  margin-bottom: 30px;
}

#RowBlog {
  display: flex;
  flex-direction: column;
  margin: 50px 0px 0px 0px;
  align-items: center;
  text-align: center;
}

.BlogListGrid {
  max-width: 1200px;
  display: grid;
  margin-left: -2rem;
  column-gap: 2em;
  row-gap: 2em;
  grid-template-columns: repeat(3, 1fr);
  
}

.BlogCard {
  border-radius: 25px;
  width: 400px;
  height: auto;
  margin: 10px 0 5px 0;
  text-align: left;
  box-shadow: 0 4px 20px rgba(0,0,0,.05);
  overflow:hidden;
  cursor: pointer;
}

.BlogCard img {
  width: 100%;
  height: auto;
  margin-bottom: 5px;
}

.BlogTitle {
  font-size: 20px;
  font-weight: bold;
  margin: 5px 0px 0px;
}

.RowFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0px 40px 0px;
}

#RowCopyright {
  text-align: center;
  margin-top: 60px;
  color:rgba(0, 0, 0, 0.7);
  font-size: 14px;
}

.Email a {
  color: #6800D0;
  font-size: 14p;
  text-decoration: underline;
}

.Blog {
  display: flex;
  flex-direction: column;
  max-width: 1052px;
  font-size: 18px;
  padding: 10px;
}

.Blog h4 {
  font-size: 24px;
  text-decoration: underline;
}

.Blog em {
  font-weight: bold;
}

.Blog a {
  text-decoration: underline;
}

.Blog img {
  border-radius: 20px;
  width: 100%;
}

.BlogHeader {
  font-size: 32px;
  margin: 25px 0px;
  font-weight: 500;
  text-align: center;
}

/******** Tablet *********/

@media (min-width : 768px) and (max-width : 1024px) {

  .LblLogo {
    width: 260px;
  }

  #Nav {
    min-width: 750px;
  }

  #NavLinks {
    margin: 0 10px 0 20px;
    font-size: 16px;
  } 

  .TextLarge {
    font-size: 30px;
  }

  .TextMedium {
    font-size: 23px;
  }

  .TextSmall {
    font-size: 16px;
  }

  #RowMainLeft {
    max-width: none;
    padding: 100px 100px 50px 100px;
    text-align: center;
    align-items: center;
  }

  .Mockup {
    object-fit: contain;
    max-height: 400px;
    margin-top: 30px;
  }
  
  .AboutImage {
    width: 180px;
    height: 180px;
    border-radius: 90px;
  }
  
  .BlogListGrid { 
    margin-left: 0rem;
    column-gap: 0em;
    grid-template-columns: repeat(1, 1fr) 
  }

  .Blog {
    max-width: 767px;
  }

  .Blog h4 {
    font-size: 20px;
  }

  .Blog img {
    max-width: 100%;
  }

  .BlogHeader {
    font-size: 24px;
  }

  .BlogCard {
    width: 600px;
  }
}

/******** Mobile *********/

@media (max-width: 767px) {

  .LblLogo {
    width: 240px;
  }

  .TextLarge {
    font-size: 24px;
  }
  
  .TextMedium {
    font-size: 18px;
  }
  
  .TextSmall {
    font-size: 14px;
  }

  #Nav {
    min-width: 100px;
    justify-content: center;
  }

  #NavLinks {
    display: none;
  }

  #RowMainLeft {
    max-width: none;
    padding: 60px 10px 10px 10px;
    text-align: center;
    align-items: center;
  }

  .AppIcon {
    border-radius: 8px;
    width: 50px;
  }
  
  #RowMainRight {
    display: none;
  }

  .AppRow {
    margin: 30px 0 10px 0;
  }

  .Mockup {
    max-height: 310px;
    margin-top: 30px;
  }

  .AppLogo {
    margin: 10px 0 0px;
    width: 180px;
    margin-bottom: 18px;
  }

  .BrLogo {
    width: 150px;
  }

  .EzwRow {
    flex-direction: column-reverse;
  }

  .BrRow {
    flex-direction: column;
  }

  .SocialLinks img {
    max-height: 35px;
  }

  #RowValues {
    margin: 30px 0px;
    padding: 0px 10px;
  }

  .Values {
    display: flex;
    flex-direction: column;;
    margin-top: 20px;
  }

  #RowAbout {
    margin: 20px 5px 20px 5px;
    padding: 0px 0px;
  }

  #AboutUsColumns {
    flex-direction: column;
  }
  
  .AboutImage {
    width: 160px;
    height: 160px;
    border-radius: 80px;
  }
  
  .AboutInfo {
    text-align: left;
    max-width: 250px;
  }

  .Email {
    font-size: 14px;
  }

  .BlogListGrid { 
    grid-template-columns: repeat(1, 1fr); 
    margin-left: 0rem;
    column-gap: 0em;
  }

  .Blog {
    max-width: 360px;
  }

  .Blog img {
    max-width: 100%;
  }

  .BlogHeader {
    font-size: 25px;
    max-width: 360px;
  }

  .BlogCard {
    width: 340px;
  }

  .Blog h4 {
    font-size: 20px;
  }
}

/******** Animations ********/

.FadeInSection {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-out, transform 2s ease-out;
  will-change: opacity, visibility;
}

.FadeInSection.left {
  transform: translateX(-4vh);
}

.FadeInSection.right {
  transform: translateX(4vh);
}

.FadeInSection.isVisible.left, .FadeInSection.isVisible.right  {
  opacity: 1;
  transform: none;
  visibility: visible;
}